<template>
  <div class="modal-content-section payment">
    <h3 class="modal-content-title">
      Payment details
    </h3>
    <div class="modal-content-inner">
      <div class="grid">
        <div class="col-4">
          <p class="modal-inner-title">
            Amount
          </p>
          <p class="modal-inner-copy notranslate">
            <flag class="icon-flag" :name="payment.currency.id" />
            {{ payment.amount | numeral('0,0.00') }} {{ payment.currency.id }}
          </p>
        </div>
        <template v-if="hasFee">
          <div class="col-4">
            <p class="modal-inner-title">
              Fee
            </p>
            <p class="modal-inner-copy notranslate">
              {{ payment.fee | numeral('0,0.00') }} {{ payment.currency.id }}
            </p>
          </div>
          <div class="col-4">
            <p class="modal-inner-title">
              Total
            </p>
            <p class="modal-inner-copy notranslate">
              {{ payment.total | numeral('0,0.00') }} {{ payment.currency.id }}
            </p>
          </div>
        </template>
      </div>
    </div>

    <div class="modal-content-inner">
      <div class="grid">
        <div class="col-4">
          <p class="modal-inner-title">
            Status
          </p>
          <p class="modal-inner-copy notranslate has-tooltip" :class="payment.statusClass">
            {{ payment.statusLabel | capitalize }}
            <icon v-if="payment.reject_reason" class="icon-tooltip" name="ic_info_2" v-tooltip="{content: payment.reject_reason}" />
          </p>
        </div>
        <div class="col-4">
          <p class="modal-inner-title">
            Type
          </p>
          <p class="modal-inner-copy notranslate">
            {{ payment.type | capitalize }}
          </p>
        </div>

        <div class="col-4" v-if="payment.completed_at">
          <p class="modal-inner-title">
            Completed
          </p>
          <p class="modal-inner-copy notranslate icon-calendar">
            {{ payment.completed_at | moment('DD-MM-YYYY HH:mm:ss') }}
          </p>
        </div>

        <div class="col-4" v-else>
          <p class="modal-inner-title">
            Created
          </p>
          <p class="modal-inner-copy notranslate icon-calendar">
            {{ payment.createdAtMs | moment('DD-MM-YYYY HH:mm:ss') }}
          </p>
        </div>

        <div class="col-4" v-if="payment.reference">
          <p class="modal-inner-title">
            Reference
          </p>
          <p class="modal-inner-copy notranslate">
            {{ payment.reference }}
          </p>
        </div>
      </div>
    </div>

    <h3 v-if="hasFiles" class="modal-content-title">Attachments</h3>
    <div v-if="hasFiles" class="modal-content-inner">
      <div class="grid">
        <div class="col-4" v-for="file in payment.files" :key="file.name">
          <p class="modal-inner-title">
            {{ file.type | capitalize }}
          </p>
          <a class="modal-inner-copy link-icon" @click.prevent="fileDownload(file)">
            <icon name="icp_confirm" />
            <span>{{ file.name }}</span>
          </a>
        </div>
      </div>
    </div>

    <h3 v-if="hasCounterParty" class="modal-content-title">
      Account details
    </h3>
    <div v-if="hasCounterParty" class="modal-content-inner">
      <div class="grid">
        <div v-if="payment.counterparty.bank_account_holder_name" class="col-6">
          <p class="modal-inner-title">
            Bank Account Holder Name
          </p>
          <p class="modal-inner-copy notranslate">
            {{ payment.counterparty.bank_account_holder_name }}
          </p>
        </div>

        <div v-if="payment.counterparty.iban" class="col-6">
          <p class="modal-inner-title notranslate">
            IBAN
          </p>
          <p class="modal-inner-copy notranslate">
            {{ payment.counterparty.iban }}
          </p>
        </div>

        <div v-if="payment.counterparty.bic_swift" class="col-6">
          <p class="modal-inner-title notranslate">
            BIC/SWIFT
          </p>
          <p class="modal-inner-copy notranslate">
            {{ payment.counterparty.bic_swift }}
          </p>
        </div>
      </div>
    </div>

    <slot #buttons/>
  </div>
</template>

<script>
import Icon from '@/libs/Icon'
import Flag from '@/libs/Flag';

export default {
  name: 'PaymentDetailsOther',
  props: ['payment'],
  components: {
    Icon,
    Flag
  },
  computed: {
    hasFiles () {
      return this.payment.files && this.payment.files.length > 0
    },
    hasCounterParty () {
      return Object.keys(this.payment.counterparty).length > 0
    },
    hasFee () {
      return this.payment.purpose?.id === 'MAIN'
          || this.payment.purpose?.id === 'REPLENISHMENT_FROM_THE_CARD'
    }
  },
  methods: {
    fileDownload(file) {
      return this.$sdk.payment.downloadFile(file)
    }
  }
}
</script>

<style lang="less">
.link-icon {
  position: relative;
  align-items: center;
  cursor: pointer;

  svg {
    height: 14px;
    width: 14px;

    fill: #fff;
    transition: all .1s ease;
    margin-right: 6px;
    position: relative;
    top: 1px;
  }

  span {
    display: inline-block;
    position: relative;
    font-size: 15px;

    &:before {
      content: ' ';
      height: 1px;
      position: absolute;
      left: 0;
      width: 100%;
      bottom: -2px;
      background: #FFF;
      opacity: .3;
    }
  }

  &:hover {
    span:before {
      display: none;
    }
  }
}
</style>
