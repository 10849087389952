<template>
  <div class="wallet-wrapper">
    <div class="wallet-header" :class="{'sign-mode': signMode}" ref="walletHeader">
      <slot name="WalletHeaderLeft">
        <div class="wallet-header-left">
          <div v-if="!signMode">
            <h1 class="headline">{{ wallet ? 'All activity' : 'Dashboard' }}</h1>
          </div>
          <div v-else>
            <span @click="signMode = false" class="back-to-transactions">
              <icon name="ic_left" />
              <span>Back to history</span>
            </span>
          </div>
        </div>
      </slot>

      <div class="wallet-header-right" v-if="draftCount > 0 && permissions.canPaymentSign">

        <div class="wallet-summary" ref="walletSummary">
          <div v-if="!signMode">
            <span class="yellow-colored">Payments to sign: {{ draftCount }}</span>
            <span class="show-draft" @click="signMode = true">View all</span>
          </div>

          <transition name="fade">
            <div v-if="signMode && !!paymentsSelected.length" >
              <div class="sign-drafts">
                <button  class="wallet-sign-button" @click="signAction">
                  <span>Sign</span>
                  <span class="sign-count">{{ paymentsSelected.length }}</span>
                </button>
              </div>
            </div>
          </transition>

        </div>

      </div>
    </div>

    <div class="shadow-line">
      <div class="wallet scrollbar-hide" :class="{'wallet-sign': signMode}" :style="{height: scrollbarHeight}" ref="wallet-scroll-section">
        <div v-for="(group, timestamp) in paymentsGroups" v-bind:key="timestamp" class="wallet-group">
          <div class="wallet-group-date"> {{ parseInt(timestamp) | moment('DD MMM') }} {{ getPaymentYear(timestamp) }}</div>
          <div class="wallet-group-payments">
            <div class="wallet-payment notranslate" :class="{checked: isSelectedPayment(payment.id), exchange: isCurrencyExchange(payment)}" v-for="payment in group" :key="payment.id" @click="clickOnPayment(payment.id)">
              <div class="wallet-payment-icon-marker" v-if="isDraft(payment)" v-tooltip="{content: 'Payment not signed'}"/>
              <div class="wallet-payment-icon" v-tooltip="{content: getIconTooltip(payment.entity)}">
                <icon :name="getIcon(payment)" class="wallet-payment-icon-type"/>
                <icon name="ic_check" v-if="signMode" class="wallet-payment-icon-check"/>
              </div>
              <div class="wallet-payment-title" v-html="getTitle(payment)"></div>
              <div class="wallet-payment-amount">
                {{ payment.type === 'credit' ? '+' : '-' }} {{ payment.amount | numeral('0,0.00') }} {{ payment.currency_id }}
              </div>
              <div class="wallet-payment-time">
                <span>{{ payment.createdAtMs | moment('HH:mm') }}</span>
                <template v-if="isCurrencyExchange(payment)">
                  <span :class="paymentClass(payment.currencyExchange)">{{ payment.currencyExchange.status | capitalize }}</span>
                </template>
                <template v-else>
                  <span :class="paymentClass(payment)">{{ payment.status | capitalize }}</span>
                </template>
                <template v-if="!isCurrencyExchange(payment) && hasReference(payment)">
                  <span class="reference">
                    {{ payment.reference.trim() }}
                  </span>
                </template>
                <template v-if="isCurrencyExchange(payment) && payment.currencyExchange && payment.currencyExchange.rate">
                  <span class="rate">
                    Rate: {{ payment.currencyExchange.rate }}
                  </span>
                </template>
                <template v-if="isCardPayment(payment) && payment.merchant">
                  <span class="mcc">
                    {{ payment.merchant.category_code }}
                  </span>
                </template>
              </div>
              <div class="wallet-payment-adesc" v-if="isCurrencyExchange(payment) && payment.currencyExchange && payment.currencyExchange.creditAmount">
                {{ payment.type === 'credit' ? '-' : '+' }}
                {{ (payment.type === 'credit' ? payment.currencyExchange.debitAmount : payment.currencyExchange.creditAmount) | numeral('0,0.00')}}
                {{ payment.type === 'credit' ? payment.currencyExchange.debitCurrencyId : payment.currencyExchange.creditCurrencyId }}
              </div>
            </div>
          </div>
        </div>

        <infinite-loading :identifier="identifierFormData" @infinite="loadMore">
          <template #no-results>
            <div class="no-payments-result">Transactions list is empty</div>
          </template>
          <template #no-more>
            <div class="no-payments-more">No more transactions</div>
          </template>
        </infinite-loading>
      </div>
    </div>

  </div>
</template>

<script>
import Icon from '@/libs/Icon'
import groupBy from 'lodash/groupBy'
import PaymentDetailsModal from '../../modals/PaymentDetailsModal'
import {mapGetters, mapState} from 'vuex';
import InfiniteLoading from 'vue-infinite-loading';
import ConfirmModal from "../../modals/ConfirmModal";

import { isEqualInt } from "../../../libs/functions";

export default {
  name: 'WalletPayments',
  components: {
    Icon,
    InfiniteLoading
  },
  props: {
    id: {
      type: [String, Number],
      default: 0
    }
  },
  data () {
    return {
      initialized: false,
      signMode: false,
      paymentsSelected: [],
      scrollbarHeight: 0
    }
  },
  mounted() {
      this.calculateScrollbarHeight()
  },
  computed: {
    ...mapGetters([
      'permissions',
      'getBalanceById',
      'getActiveVisibleBalances'
    ]),
    ...mapState({
      balances: state => state.balance.items,
      payments: state => state.wallet.payments,
      draftCount: state => state.wallet.draftCount,
      identifier: state => state.wallet.identifier
    }),

    wallet () {
      return this.getBalanceById(this.id)
    },

    paymentsGroups () {
      return groupBy(this.payments, item => {
        return item.group
      })
    },

    formData () {
      return {
        wallet_id: this.id || undefined,
        status: this.signMode ? 'draft': undefined
      }
    },

    identifierFormData () {
      return {
        data: this.formData,
        identifier: this.identifier
      }
    },

    isDashboardPage () {
      return this.id === 0
    }
  },
  watch: {
    'getActiveVisibleBalances' (newVal, oldVal) {
      setTimeout(() => {
        this.calculateScrollbarHeight()
      }, 100)
    },
    /**
     * Switch to default mode when
     * number of draft == 0
     */
    draftCount: function (newVal, oldVal) {
      if ((oldVal > 0) && (newVal === 0)) {
        this.signMode = false
      }
    },

    formData (newData) {
      this.$store.dispatch('wallet.filter.set', newData)
      this.$store.dispatch('wallet.reload')
    }
  },

  sockets: {
    'createPayment' ({ id, filter, wallet_id, entity, type, status }) {
      if (filter === 'WALLET') {
        this.$store.dispatch('wallet.drafts')

        if (entity === 'CONVERT') {
          if (
            (this.isDashboardPage && (type === 'debit')) ||
            isEqualInt(wallet_id, this.id)
          ) {
            this.$store.dispatch('wallet.add', id)
          }
        } else {
          if (
              (this.isDashboardPage || isEqualInt(wallet_id, this.id))
           && (!this.signMode || (this.signMode && status === 'draft'))
          ) {
            this.$store.dispatch('wallet.add', id)
          }
        }
      }
    },
    'deletePayment' () {
      this.$store.dispatch('wallet.reload')
    },
    'updatePayment' ({ id, filter, status, entity, type, reference }) {
      if (filter === 'WALLET') {
        this.$store.dispatch('wallet.drafts')

        if ( entity === 'CONVERT' && type === 'debit') {
          this.$store.dispatch('wallet.update.payment.full', { payment_id: id })
        } else {
          this.$store.dispatch('wallet.update.payment.fields', {
            payment_id: id,
            fields: {
              status,
              reference
            }
          })
        }
      }
    }
  },

  created () {
    this.$store.dispatch('wallet.filter.set', this.formData)
    this.$store.dispatch('wallet.drafts')
  },

  methods: {
    calculateScrollbarHeight () {
      let ref = this.$refs.walletHeader

      let sizes = ref.getClientRects()[0]
      let height = sizes.top + 70

      this.scrollbarHeight = 'calc(100vh - ' + height + 'px)'
    },

    /**
     * Load more data
     */
    loadMore ($state) {
      return this.$store.dispatch('wallet.more').then(({ headers, data }) => {
        if (!data) {
          return
        }

        if (data.length > 0) {
          $state.loaded()
        }

        let currentPage = parseInt(headers['x-pagination-current-page'])
        let maximumPage = parseInt(headers['x-pagination-page-count'])

        if (currentPage >= maximumPage) {
          $state.complete()
        }
      })
    },

    /**
     * Dynamically get payment status class according payment status
     */
    paymentClass (payment) {
      return 'status-' + payment.status.toLowerCase()
    },

    /**
     * Check is payment has a draft status
     */
    isDraft (payment) {
      return payment.status === 'draft'
    },

    isPayment (payment) {
      return payment.entity !== 'CONVERT'
    },

    isCurrencyExchange (payment) {
      return payment.entity === 'CONVERT'
    },

    isCardPayment (payment) {
      return payment.entity === 'CARD'
    },

    hasReference (payment) {
      if (typeof payment.reference === 'string') {
        return payment.reference.length > 0
      }

      return false
    },

    /**
     * Return name of icon
     * @param payment
     */
    getIcon (payment) {
      return 'p_' + payment.icon.toLowerCase()
    },

    /**
     * Get tooltip text
     */
    getIconTooltip(entity) {
      return entity[0] + entity.substring(1).toLowerCase()
    },

    /**
     * Get title for payment
     */
    getTitle (payment) {
      if (this.isCurrencyExchange(payment)) {
        return payment.reference
      }

      if (this.isCardPayment(payment)) {
        return payment.counterparty_name
      }

      let str = payment.type === 'debit' ? 'To' : 'From'
      return str + ' ' + payment.counterparty_name
    },

    /**
     * Show modal of payment details
     */
    getPaymentDetails (id) {
      this.$modal.show(PaymentDetailsModal, { id: id })
    },

    /**
     * Check if payment has been checked
     * @param id
     */
    isSelectedPayment (id) {
      return this.paymentsSelected.indexOf(id) !== -1
    },

    /**
     * Toggle selection
     *
     * @param id
     */
    toggleSelection (id) {
      if (!this.signMode) {
        return
      }

      let index = this.paymentsSelected.indexOf(id)

      if (index !== -1) {
        this.paymentsSelected.splice(index, 1)
      } else {
        this.paymentsSelected.push(id)

        this.$sdk.payment.sign({
          id: this.paymentsSelected,
        }, 1).then(response => {
          if (!Array.isArray(response.data)) {
            this.toggleSelection(id)

            // Check if response is object, and it has some message errors
            if (response.data.id && response.data.id.length > 0) {
              this.$notice.info(response.data.id[0])
            }
          }
        })
      }
    },

    /**
     * Process click on the payment
     *
     * @param id
     */
    clickOnPayment (id) {
      if (this.signMode) {
        this.toggleSelection(id)
      } else {
        this.getPaymentDetails(id)
      }
    },

    /**
     * Display year
     *
     * @param timestamp
     * @return {number|string}
     */
    getPaymentYear (timestamp) {
      let year = new Date().getFullYear();
      let paymentYear = parseInt(this.$moment(parseInt(timestamp)).format('YYYY'))

      return (year !== paymentYear) ? paymentYear : ''
    },

    /**
     * Sign selected payments
     */
    signAction () {
      this.$modal.show(ConfirmModal, {
        submit: ({ code }) => {
          const data = {
            id: this.paymentsSelected,
            code: code
          }

          return this.$sdk.payment.sign(data, 0).then(async response => {
            if (response.data.length === 0) {
              await this.$store.dispatch('wallet.filter.set', this.formData)
              await this.$store.dispatch('wallet.reload')
              await this.$notice.success('The payments were successfully signed')

              for (let payment_id of [...this.paymentsSelected]) {
                this.toggleSelection(payment_id)
              }
            }

            return response
          })
        }
      })
    }
  }
}
</script>

<style lang="less">

.wallet {
  width: 100%;
  text-align: left;
  min-height: 600px;
  //border: 1px solid rgba(255, 255, 255, 0.09);

  &-wrapper {
    position: relative;
  }

  &-group {
    &-date {
      margin: 40px 30px 20px;

      @media all and (max-width: 768px) {
        margin: 30px 20px;
      }
    }
    &-payments {

    }
  }

  & > div:first-child > .wallet-group-date {
    margin-top: 20px;
  }

  .wallet-payment {
    display: grid;
    position: relative;
    grid-template-columns: 50px auto 1fr;
    grid-template-rows: auto auto;
    grid-template-areas:
        "icon title amount"
        "icon desc desc";
    grid-column-gap: 30px;
    grid-row-gap: 10px;

    padding: 30px 30px 30px 30px;
    transition: all 0.2s ease;

    @media all and (max-width: 768px) {
      padding: 30px 20px;
      grid-column-gap: 15px;
    }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      align-self: center;

      grid-area: icon;

      width: 50px;
      min-width: 50px;
      height: 50px;

      background: #262629;
      border-radius: 50%;
      transition: transform .1s ease;

      &-marker {
        width: 11px;
        height: 11px;

        background: #FDE46A;
        border-radius: 50%;

        border: 2px solid #19191C;
        box-sizing: content-box;

        position: absolute;
        left: 68px;
        top: 34px;

        z-index: 2;

        @media all and (max-width: 768px) {
          text-align: right;
          left: 58px;
        }
      }
    }

    &-time {
      color: rgba(255, 255, 255, 0.6);
      line-height: 1.4em;

      grid-area: desc;

      span {
        margin-left: 10px;

        &:before {
          content: "•";
          font-size: 12px;
          margin-right: 10px;
          color: rgba(255, 255, 255, 0.6);
        }

        &:first-child {
          margin-left: 0;

          &:before {
            display: none;
          }
        }
      }
    }

    &-title {
      margin-top: 2px;
      line-height: 1.4em;

      grid-area: title;
    }

    &-amount {
      margin-top: 2px;
      line-height: 1.4em;
      white-space: nowrap;
      text-align: right;

      grid-area: amount;
    }

    &-adesc {
      color: rgba(255, 255, 255, 0.6);
      line-height: 1.4em;
      white-space: nowrap;

      text-align: right;
      grid-area: adesc;
    }

    &.exchange {
      grid-template-areas:
        "icon title amount"
        "icon desc adesc";
    }

    &:hover {
      background-color: #262629;
      box-shadow: 0 4px 9px 0 rgba(0, 0, 0, .3);
      cursor: pointer;
      border-radius: 3px;

      .wallet-payment-icon {
        background-color: #FFF;

        transform: scale(1.1);

        &-type {
          polygon,
          path {
            fill: #19191C;
            opacity: 1;
          }
        }
      }
    }
  }

  &-summary {
    .show-draft {
      margin-left: 10px;
      cursor: pointer;
    }

    .sign-drafts {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      @media all and (max-width: 768px) {
        justify-content: flex-start;
      }
    }
  }

  &-header {
    display: grid;
    flex-direction: row;
    padding: 0 30px;
    margin: 30px 0;
    min-height: 38px;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr;
    align-items: center;

    @media all and (max-width: 767px) {
      grid-template-columns: 1fr;
      padding: 30px 20px;
      min-height: 97px;
    }

    &.sign-mode {
      @media all and (max-width: 767px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 30px 20px;
        min-height: 97px;
      }
    }

    &-left {
      .back-to-transactions {
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        cursor: pointer;

        svg {
          margin-right: 16px;
          fill: #FFFFFF;
          transform: none;
          position: relative;
          width: 24px;
          height: 24px;
        }
      }
    }
    &-right {
      text-align: right;
      line-height: 1.4em;

      @media all and (max-width: 767px) {
        text-align: left;
      }
    }
  }
}

.wallet.wallet-sign {
  .wallet-payment {

    .wallet-payment-icon {
      &-type {}
      &-check {
        width: 20px;
        height: 20px;

        display: none;
      }

      &-marker {
        display: none;
      }
    }

    &:hover {
      .wallet-payment-icon {
        background: #FFFFFF;

        transform: scale(1.1);

        &-check {
          polygon {
            fill: #19191C;
          }
        }
      }
    }

    &.checked {
      .wallet-payment-icon {
        background: #FFF;

        &-type {
          display: none;
        }
        &-check {
          display: block;

          polygon {
            fill: #19191C;
          }
        }
      }
    }
  }
}

svg {
  &.wallet-payment-icon-type {
    polygon,
    path {
      fill: #FFFFFF;
      opacity: 0.7;
    }
  }
}

.loading {
  margin: 30px;
}

.wallet-payments-delimiter {
  height: 2px;
  width: 30px;
  background-color: #373739;
  margin: 30px auto;
}

.wallet-sign-button {
  display: flex;
  justify-content: center;
  margin-left: 20px;
  padding: 10px 20px;
  border-radius: 2px;
  min-width: 105px;

  background-color: #FFF;
  color: #19191C;

  font-size: 14px;
  font-weight: 500;
  line-height: 17px;

  cursor: pointer;

  .sign-count {
    margin-left: 5px;
    padding: 0 5px;

    border-radius: 8px;
    background-color: #19191C20;

    font-size: 12px;
  }
}

.wallet-balance {
  display: flex;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;
  margin-top: 30px;

  &-group {
    display: flex;
    grid-column-gap: 10px;
    flex-direction: column;
    justify-content: center;
  }

  &-flag {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    align-self: center;
    width: 50px;
    min-width: 50px;
    height: 50px;
    background: #ffffff21;
    border-radius: 50%;
  }

  &-amount {
    font-size: 18px;
    font-weight: 500;
  }
}

.no-payments {
  &-result {
    padding: 25px 15px;
    color: #666;
  }
  &-more {
    padding: 25px 15px;
    color: #666;
  }
}
</style>
