<template>
  <div v-if="payment" v-cloak class="modal small" v-body-scroll-lock="true">
    <div class="modal-content">
      <div @click="close" class="menu-close" data-dismiss="modal" aria-label="Close">
        <span class="menu-close-icon" />
      </div>

      <component :is="detailsView" :payment="payment">
        <slot name="buttons">
          <div class="modal-content-bottom-panel">
            <div class="pull-left">
              <button v-if="canDownloadConfirmation" class="next-form-button">
                <icon name="icp_confirm" />
                <span @click.prevent="getConfirmation" class="button-payment-confirm">Get payment confirmation</span>
              </button>

              <button @click.prevent="cancel" v-if="canCancelPayment" class="next-form-button">
                <icon name="icp_cancel" />
                <span>Cancel payment</span>
              </button>

              <button @click.prevent="repeatPayment" v-if="canRepeatPayment" class="next-form-button">
                <icon name="ic_repeat" />
                <span>Repeat payment</span>
              </button>

              <button @click.prevent="deleteDraft" v-if="canDeleteDraftPayment" class="next-form-button">
                <icon name="ic_delete" />
                <span class="red-colored">Delete</span>
              </button>
            </div>

            <div class="pull-right">
              <button @click="sign" v-if="canSignPayment" class="next-form-button">
                <icon name="icp_check" />
                <span>Sign payment</span>
              </button>
              <button @click="close" v-else class="next-form-button">
                <icon name="icp_close" />
                <span>Close</span>
              </button>
            </div>
          </div>
        </slot>
      </component>
    </div>
  </div>
</template>

<script>
import Icon from '../../libs/Icon'
import {mapGetters, mapState} from 'vuex'
import PaymentCreateModal from '@/components/modals/PaymentCreateModal'
import PaymentDetailsConversion from "@/components/modals/payment-details/PaymentDetailsConversion";
import PaymentDetailsOther from "@/components/modals/payment-details/PaymentDetailsOther";
import PaymentDetailsCard from "@/components/modals/payment-details/PaymentDetailsCard";
import ConfirmModal from "./ConfirmModal";
import debounce from "lodash/debounce";

/**
 * @typedef Payment
 * @type {object}
 * @property {object} currency
 * @property {object} counterparty
 * @property {string} entity - type of item
 * @property {object} permissions - list of payment permissions
 * @property {boolean} permissions.canDelete - Can Delete payment
 * @property {boolean} permissions.canCancel - can cancel payment
 * @property {boolean} permissions.canDownloadConfirmation - can download pdf confirmation
 * @property {boolean} permissions.canSign - can sign payment
 */
export default {
  name: 'PaymentDetailsModal',
  components: {
    PaymentDetailsConversion,
    PaymentDetailsOther,
    PaymentDetailsCard,
    Icon
  },
  props: {
    id: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      /** @type {Payment|null} */
      payment: null
    }
  },
  computed: {
    ...mapGetters(['permissions']),
    ...mapState({
      user: state => state.user.data,
      balances: state => state.balance.items
    }),

    canDownloadConfirmation () {
      return this.payment.permissions.canDownloadConfirmation
    },
    canCancelPayment () {
      return this.payment.permissions.canCancel
          && this.permissions.canPaymentCancel
    },

    canDeleteDraftPayment () {
      return this.payment.permissions.canDelete
          && this.permissions.canPaymentDelete
    },

    canSignPayment () {
      return this.payment.permissions.canSign
          && this.permissions.canPaymentSign
    },

    isCurrencyExchange () {
      return this.payment.entity === 'CONVERT'
    },

    isCardPayment () {
      return this.payment.entity === 'CARD'
    },

    canRepeatPayment () {
      return this.payment.status === 'completed'
          && this.payment.type === 'debit'
          && this.payment.entity === 'PAYMENT'
          && this.payment.beneficiary_id !== null
          && this.permissions.canPaymentDraftCreate
    },

    detailsView () {
      if (this.isCurrencyExchange) {
        return 'payment-details-conversion'
      } else if (this.isCardPayment) {
        return 'payment-details-card'
      } else {
        return 'payment-details-other'
      }
    },
  },
  asyncComputed: {
    /**
     * Load payment info
     */
    payment () {
      if (!this.id) {
        return null
      }

      return this.$sdk.payment.show(this.id).then(response => {
        let data = response.data

        if (data.currencyExchange) {
          data.currencyExchange.statusClass = this.getPaymentStatusClass(data.currencyExchange.status)
          data.currencyExchange.statusLabel = this.getPaymentStatusLabel(data.currencyExchange.status)
        } else {
          data.statusLabel = this.getPaymentStatusLabel(data.status)
          data.statusClass = this.getPaymentStatusClass(data.status)
        }

        return data
      })
    }
  },
  sockets: {
    'updatePayment' () {
      this.$asyncComputed.payment.update()
    }
  },
  methods: {
    /**
     * Cancel payment
     */
    cancel: debounce( function () {
      if (!this.id) {
        return
      }
      this.close()

      this.$sdk.payment.cancel(this.id).then(response => {
        if (response.data.success) {
          this.$notice.success('Payment was canceled successfully.')
        }
      }).catch(() => {
        this.$notice.error('Something was wrong. Please reload page and try again')
      })
    }, 300),

    /**
     * delete draft payment
     */
    deleteDraft: debounce( function () {
      if (!this.id) {
        return
      }

      let data = {
        id: [this.id]
      }

      this.$sdk.payment.delete({ data }).then(response => {
        if (Object.keys(response.data).length === 0) {
          this.close()
          this.$notice.success('Draft payment was deleted successfully.')
        }
      }).catch(() => {
        this.$notice.error('Something was wrong. Please reload page and try again')
      })
    }, 300),

    /**
     * Close current modal
     */
    close () {
      this.$emit('close')
    },

    sign () {
      this.$modal.show(ConfirmModal, {
        submit: ({ code }) => {
          const params = {
            id: this.payment.id,
            code: code
          }

          return this.$sdk.payment.sign(params, 0).then(response => {
            if (Object.keys(response.data).length === 0) {
              this.$modal.hideAll()
              this.$notice.success('Payment was sent signed')
            }

            return response
          })
        }
      })
    },

    /**
     * Return correct status name by type
     * @param type
     * @return {string}
     */
    getPaymentStatusLabel (type) {
      if (type === 'draft') {
        return 'Signature required'
      }

      return type
    },

    /**
     * Dynamically get payment status color according payment status
     *
     * @param status
     * @return {string}
     */
    getPaymentStatusClass (status) {
      return 'status-' + status.toLowerCase()
    },

    /**
     * Repeat payment button
     */
    repeatPayment () {
      this.$modal.show(PaymentCreateModal, {
        reference: this.payment.reference,
        beneficiaryId: this.payment.beneficiary_id,
        walletId: this.payment.wallet_id,
        amount: this.payment.amount
      })
    },

    /**
     * Download file with payment confirmation action
     * @return {Promise<AxiosResponse<*>>}
     */
    getConfirmation () {
      return this.$sdk.payment.getConfirmation(this.payment.id)
    }
  }
}
</script>
