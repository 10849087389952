<template>
  <div class="modal-content-section payment">
    <h3 class="modal-content-title">
      Payment details
    </h3>
    <div class="modal-content-inner">
      <div class="grid">
        <template v-if="isCardPaymentWithExchange">
          <div class="col-4">
            <p class="modal-inner-title">Merchant's charge</p>
            <p class="modal-inner-copy notranslate">
              <flag class="icon-flag" :name="payment.transaction_currency_id" />
              {{ payment.transaction_amount | numeral('0,0.00') }} {{ payment.transaction_currency_id }}
            </p>
          </div>

          <div class="col-4">
            <p class="modal-inner-title">Fee</p>
            <p class="modal-inner-copy notranslate">
              {{ payment.fee | numeral('0,0.00') }} {{ payment.currency.id }}
            </p>
          </div>

          <div class="col-4">
            <p class="modal-inner-title">Exchanged amount</p>
            <p class="modal-inner-copy notranslate">
              <flag class="icon-flag" :name="payment.currency.id" />
              {{ payment.amount | numeral('0,0.00') }} {{ payment.currency.id }}
            </p>
          </div>

          <div class="col-4">
            <p class="modal-inner-title">Exchange rate</p>
            <p class="modal-inner-copy notranslate">
              1 {{ payment.currency_id }} = {{ payment.transaction_exchange_rate }} {{ payment.transaction_currency_id }}
            </p>
          </div>
        </template>

        <template v-else>
          <div class="col-4">
            <p class="modal-inner-title">Merchant's charge</p>
            <p class="modal-inner-copy notranslate">
              <flag class="icon-flag" :name="payment.currency.id" />
              {{ payment.amount | numeral('0,0.00') }} {{ payment.currency.id }}
            </p>
          </div>

          <div class="col-4">
            <p class="modal-inner-title">Fee</p>
            <p class="modal-inner-copy notranslate">
              {{ payment.fee | numeral('0,0.00') }} {{ payment.currency.id }}
            </p>
          </div>
        </template>

        <div class="col-4">
          <p class="modal-inner-title">Total</p>
          <p class="modal-inner-copy notranslate">
            {{ payment.total | numeral('0,0.00') }} {{ payment.currency.id }}
          </p>
        </div>

        <div class="col-4">
          <p class="modal-inner-title">Card</p>
          <p class="modal-inner-copy notranslate icon-card">
            {{ payment.card_masked_number }}
          </p>
        </div>
      </div>
    </div>

    <div class="modal-content-inner">
      <div class="grid">
        <div class="col-4">
          <p class="modal-inner-title">
            Status
          </p>
          <p class="modal-inner-copy notranslate has-tooltip" :class="payment.statusClass">
            {{ payment.statusLabel | capitalize }}
            <icon v-if="payment.reject_reason" name="ic_info_2" class="icon-tooltip" v-tooltip="{content: payment.reject_reason}" />
          </p>
        </div>

        <div class="col-4">
          <p class="modal-inner-title">
            Type
          </p>
          <p class="modal-inner-copy notranslate">
            {{ payment.type | capitalize }}
          </p>
        </div>

        <div class="col-4" v-if="payment.completed_at">
          <p class="modal-inner-title">
            Completed
          </p>
          <p class="modal-inner-copy notranslate icon-calendar">
            {{ payment.completed_at | moment('DD-MM-YYYY HH:mm:ss') }}
          </p>
        </div>
        <div class="col-4" v-else>
          <p class="modal-inner-title">
            Created
          </p>
          <p class="modal-inner-copy notranslate icon-calendar">
            {{ payment.createdAtMs | moment('DD-MM-YYYY HH:mm:ss') }}
          </p>
        </div>
      </div>
    </div>

    <template v-if="payment.merchant">
      <h3 class="modal-content-title">Merchant details</h3>
      <div class="modal-content-inner">

        <div class="grid">
          <div class="col-4">
            <p class="modal-inner-title">Merchant</p>
            <p class="modal-inner-copy notranslate">
              {{ payment.merchant.name }}
            </p>
          </div>

          <div class="col-4">
            <p class="modal-inner-title">Address</p>
            <p class="modal-inner-copy notranslate icon-location">
              {{ payment.merchant.address }}
            </p>
          </div>

          <div class="col-4">
            <p class="modal-inner-title">Merchant ID</p>
            <p class="modal-inner-copy notranslate">
              {{ payment.merchant.external_id }}
            </p>
          </div>

          <div class="col-4">
            <p class="modal-inner-title">MCC</p>
            <p class="modal-inner-copy notranslate">
              {{ payment.merchant.category_code }}
            </p>
          </div>
        </div>

      </div>
    </template>

    <slot #buttons/>
  </div>
</template>

<script>
import Flag from "@/libs/Flag";
import Icon from "@/libs/Icon";
export default {
  name: 'PaymentDetailsConversion',
  components: {
    Flag,
    Icon
  },
  /**
   * @property payment.merchant
   * @property payment.merchant.name
   * @property payment.merchant.address
   * @property payment.merchant.external_id
   * @property payment.merchant.id
   * @property payment.merchant.category_code
   */
  props: ['payment'],
  computed: {
    isCardPaymentWithExchange () {
      return this.payment.transaction_amount
          && this.payment.transaction_currency_id
          && this.payment.transaction_exchange_rate
    }
  }
}
</script>
