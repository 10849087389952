<template>
  <div class="modal-content-section" v-if="conversion">
    <h3 class="modal-content-title">
      Conversion details
    </h3>
    <div class="modal-content-inner">
      <div class="grid">
        <div class="col-4" v-if="conversion.debitAmount">
          <p class="modal-inner-title">
            Sold
          </p>
          <p class="modal-inner-copy notranslate">
            <flag class="icon-flag" :name="conversion.debitCurrencyId" />
            {{ conversion.debitAmount | numeral('0,0.00') }} {{ conversion.debitCurrencyId }}
          </p>
        </div>
        <div class="col-4">
          <p class="modal-inner-title">
            Fee
          </p>
          <p class="modal-inner-copy notranslate">
            {{ conversion.fee | numeral('0,0.00') }} {{ conversion.debitCurrencyId }}
          </p>
        </div>
        <div class="col-4" v-if="conversion.creditAmount">
          <p class="modal-inner-title">
            Received
          </p>
          <p class="modal-inner-copy notranslate">
            <flag class="icon-flag" :name="conversion.creditCurrencyId" />
            {{ conversion.creditAmount | numeral('0,0.00') }} {{ conversion.creditCurrencyId }}
          </p>
        </div>
        <div class="col-4" v-if="conversion.rate">
          <p class="modal-inner-title">
            Exchange rate
          </p>
          <p class="modal-inner-copy notranslate">
            1 {{ conversion.debitCurrencyId }} = {{ conversion.rate }} {{ conversion.creditCurrencyId }}
          </p>
        </div>
      </div>
    </div>
    <div class="modal-content-inner">
      <div class="grid">
        <div class="col-4">
          <p class="modal-inner-title">
            Status
          </p>
          <p class="modal-inner-copy notranslate" :class="conversion.statusClass">
            {{ conversion.statusLabel | capitalize }}
          </p>
        </div>
        <div class="col-4" v-if="conversion.completedAt">
          <p class="modal-inner-title">
            Completed
          </p>
          <p class="modal-inner-copy notranslate icon-calendar" v-if="conversion.completedAt">
            {{ conversion.completedAt | moment('DD-MM-YYYY HH:mm:ss')  }}
          </p>
        </div>
        <div class="col-4" v-else>
          <p class="modal-inner-title">
            Created
          </p>
          <p class="modal-inner-copy notranslate icon-calendar">
            {{ conversion.createdAt | moment('DD-MM-YYYY HH:mm:ss')  }}
          </p>
        </div>
      </div>
    </div>

    <slot #buttons/>
  </div>
</template>

<script>
import Flag from "@/libs/Flag.vue";

export default {
  name: 'PaymentDetailsConversion',
  components: { Flag },
  props: ['payment'],
  computed: {
    conversion () {
      return this.payment.currencyExchange
    }
  }
}
</script>
